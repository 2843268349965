.theme__post {
  p, ul, ol, h1, h2, h3, h4, h5, h6 { max-width: 48rem; }

  h1, h2, h3, h4, h5, h6 {
    font-family: Lora, serif;
    margin-bottom: 1.25rem;
    margin-top: 0.25rem;
  };

  h1 { font-size: 275%; font-style: italic; margin-bottom: 2rem; }
  h2 { font-size: 225% }
  h3 { font-size: 200% }
  h4 { font-size: 175% }
  h5 { font-size: 150% }
  h6 { font-size: 125% }

  p, ul, ol { margin-bottom: 1.25rem; }

  ol {
    list-style-type: decimal;
    margin-left: 1.5rem;
  }

  ul {
    list-style-type: disc;
    margin-left: 1.5rem;
  };

  ul > li, ol > li {
    margin-bottom: 0.5rem;
  }

  li > ul, li > ol {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  :not(pre) > code {
    font-size: 92%;
    background-color: #eee;
    padding: 3px;
  }

  a {
    color: blue;
  }

  a:visited {
    color: blueviolet
  }
};
